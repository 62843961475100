.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-content {
  text-align: left;
  padding: 20px;
  margin: 20px;
  background: #f0f0f0;
}

.App-link {
  color: #09d3ac;
}

.App-footer {
  padding: 10px;
  text-align: center;
  background: #282c34;
  color: white;
}
